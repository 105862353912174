import { FilterSvg } from 'components/svg/filter-svg';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TableSelectOptions } from 'components/table-select-options';
import { PAGE_SIZE } from 'common/constants';
import { useSearch } from 'hooks/use-search';
import type { OptionItem } from 'common/types';
import { FilterActiveSvg } from 'components/svg/filter-active-svg';
import { useOutsideClick } from 'hooks/use-outside-click';
import { useSearchParams } from 'react-router-dom';
import { FilterStyled, Icon } from './style';

interface FilterProps {
  fetcher: (prams: string) => Promise<{ options: OptionItem[]; count: number }>;
  fieldKey: string;
  searchKey?: string;
  limitKey?: string;
  filters: Record<string, string | boolean>;
  setFilters: Dispatch<SetStateAction<Record<string, string | boolean>>>;
  filterByID?: boolean;
}

export const Filter: FC<FilterProps> = ({
  fetcher,
  fieldKey,
  searchKey = 'search',
  filters,
  setFilters,
  filterByID,
  limitKey = 'size'
}) => {
  const ref = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showOptions, setShowOptions] = useState(false);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const { term, onChangeSearch, searchTerm } = useSearch();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<OptionItem[]>([]);
  const [hasMoreOptions, setHasMoreOptions] = useState(true);
  useOutsideClick(ref, () => setShowOptions(false));

  const fetchOptions = useCallback(async () => {
    setIsLoading(true);
    try {
      const { options, count } = await fetcher(
        `?${limitKey}=${limit}&${searchKey}=${searchTerm.replace(
          '+',
          ''
        )}&${Object.entries(filters)
          .map(
            ([key, value]) =>
              `${key}=${
                typeof value === 'string' ? value.replace('+', '') : value
              }`
          )
          .join('&')}`
      );
      setOptions(options);
      setHasMoreOptions(limit <= count);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [fetcher, limit, searchTerm, searchKey, filters, limitKey]);

  useEffect(() => {
    if (showOptions) {
      fetchOptions();
    }
  }, [fetchOptions, showOptions]);

  const handleSetFilters = (value: OptionItem) => {
    if (filterByID) {
      setFilters((state) => ({
        ...state,
        [fieldKey]: `${value.id}`
      }));
    } else {
      setFilters((state) => ({
        ...state,
        [fieldKey]: value.title.replace('+', '')
      }));
    }

    setShowOptions(false);
    onChangeSearch('');
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  const handleDeleteFilter = () => {
    setFilters((state) =>
      Object.fromEntries(
        Object.entries(state).filter(([key]) => key !== fieldKey)
      )
    );
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  const isActive = !!filters[fieldKey];

  return (
    <FilterStyled ref={ref}>
      {isActive ? (
        <Icon onClick={handleDeleteFilter}>
          <FilterActiveSvg />
        </Icon>
      ) : (
        <Icon onClick={() => setShowOptions((state) => !state)}>
          <FilterSvg />
        </Icon>
      )}

      {showOptions && (
        <TableSelectOptions
          isFilter
          options={options}
          onSelect={handleSetFilters}
          setLimit={setLimit}
          searchTerm={term}
          onChangeSearch={onChangeSearch}
          isLoading={isLoading}
          hasMoreOptions={hasMoreOptions}
        />
      )}
    </FilterStyled>
  );
};
