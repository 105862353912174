import styled from 'styled-components';

export const InputPhoneStyled = styled.div<{
  $invalid?: boolean;
  $isTouched?: boolean;
}>`
  .react-tel-input .form-control {
    border: none;
    min-width: 195px;
    width: 100%;
    background: ${(p) =>
      p.$invalid
        ? p.theme.colors.lightError
        : p.$isTouched
        ? p.theme.colors.coloumnHover
        : p.theme.colors.coloumn};
    border-radius: 0;
  }
  .flag-dropdown {
    background: ${(p) => p.theme.colors.coloumn};
    border: none;
  }
  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    background: ${(p) => p.theme.colors.coloumn};
  }
`;
