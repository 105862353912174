import {
  getFleetVehicleFiltersLocationsApi,
  getFleetVehicleFiltersStorageApi,
  getFleetVehicleFiltersStringApi
} from 'api/fleet-vehicle';
import type { ID } from 'common/types';
import type { HeaderItem } from 'components/data-table';
import type { ReactNode } from 'react';

export interface FleetVehicleTable {
  id: ID;
  vehicle_plate: string;
  vehicle_brand_model: string;
  driver_first_name: ReactNode;
  driver_last_name: ReactNode;
  driver_phone: ReactNode;
  vehicle_location: ReactNode;
  tires_storage_city: ReactNode;
}

export const tableHeaders: HeaderItem<FleetVehicleTable>[] = [
  {
    id: 'vehicle_plate',
    title: 'ДНЗ',
    width: '100px',
    filter: {
      fetcher: (params) =>
        getFleetVehicleFiltersStringApi(params, 'vehicle_plate'),
      searchKey: 'search_plate'
    },
    sortBy: 'plate'
  },
  {
    id: 'vehicle_brand_model',
    title: 'Марка/ модель',
    width: '180px',
    filter: {
      fetcher: (params) =>
        getFleetVehicleFiltersStringApi(params, 'vehicle_brand_model'),
      searchKey: 'search_brand_model'
    },
    nowrap: true,
    sortBy: 'brand'
  },
  {
    id: 'driver_last_name',
    title: 'Прізвище водія',
    editable: true,
    filter: {
      fetcher: (params) =>
        getFleetVehicleFiltersStringApi(params, 'driver_last_name'),
      searchKey: 'search_driver_last'
    },
    sortBy: 'driver_last'
  },
  {
    id: 'driver_first_name',
    title: 'Імʼя водія',
    editable: true,
    filter: {
      fetcher: (params) =>
        getFleetVehicleFiltersStringApi(params, 'driver_first_name'),
      searchKey: 'search_driver_first'
    },
    nowrap: true,
    sortBy: 'driver_first'
  },
  {
    id: 'driver_phone',
    title: 'Телефон водія',
    width: '151px',
    editable: true,
    filter: {
      fetcher: (params) =>
        getFleetVehicleFiltersStringApi(params, 'driver_phone'),
      searchKey: 'search_driver_phone'
    }
  },
  {
    id: 'vehicle_location',
    title: 'Місто знаходження авто',
    width: '240px',
    editable: true,
    filter: {
      fetcher: getFleetVehicleFiltersLocationsApi,
      filterByID: true,
      limitKey: 'limit'
    },
    sortBy: 'location'
  },
  {
    id: 'tires_storage_city',
    title: 'Місце зберігання шин',
    width: '205px',
    editable: true,
    filter: {
      fetcher: getFleetVehicleFiltersStorageApi,
      filterByID: true,
      limitKey: 'limit'
    },
    sortBy: 'tire_storage'
  }
];
